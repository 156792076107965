import { CertificateType } from '@dltru/dfa-models'
import { ArrowLeftOutlined, Box, Button, EditSquare, MiniModal, QuestionCircle, Space } from '@dltru/dfa-ui'
import React, { FC, useEffect, useState } from 'react'

import { UserCertificateList } from '@components/UserCertificateList'
import { transactionDetailsSelectors } from '@store/sign/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { transactionDetailsSlice } from '@store/sign'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    onSubscribe: (cert: CertificateType) => void
    onSign: (cert: CertificateType) => void
}
export const CertificateListModalWithTransactionData: FC<IComponentProps> = ({
    isModalVisible,
    setIsModalVisible,
    onSubscribe,
    onSign,
}) => {
    // const details = useSelector(transactionDetailsSelectors.selectItems)
    const isLoadingDetails = useSelector(transactionDetailsSelectors.selectIsLoading)
    const reduxDispatch = useDispatch()
    const [selectedCertificate, setSelectedCertificate] = useState<CertificateType>()
    const [certificate, setCertificate] = useState<CertificateType>()
    const [showTransactionDetails, setShowTransactionDetails] = useState<boolean>(false)

    const showDetailHandler = () => {
        setShowTransactionDetails(!showTransactionDetails)
        if(showTransactionDetails){
            setCertificate(undefined)
        }
    }

    const closeModal = () => {
        setShowTransactionDetails(false)
        setIsModalVisible(false)
        setSelectedCertificate(undefined)
        setCertificate(undefined)
        reduxDispatch(transactionDetailsSlice.actions.clearDataTransactionDetails())
    }
    const addCertificate = () => {
        if (selectedCertificate) {
            onSign(selectedCertificate)
        }
        closeModal()
    }

    const setCertificateHandle = () => {
        setCertificate(selectedCertificate)
    }
    
    useEffect(() => {
        if(certificate) {
            onSubscribe(certificate)
            showDetailHandler()
         }
    }, [certificate])

    const isCertSelected = certificate === selectedCertificate && !!certificate

    return (
        <MiniModal
            visible={isModalVisible}
            onCancel={closeModal}
            destroyOnClose
            title={showTransactionDetails ? 
                <Space size={0}>
                    <ArrowLeftOutlined className="modal-icon modal-question-icon" onClick={showDetailHandler}/>
                    Подписание документа
                </Space>
                :
                <Space size={0}>
                    <QuestionCircle className="modal-icon modal-question-icon" />
                    Выбор сертификата
                </Space>
            }
            width={680}
            closable
            footer={[
                <Box direction='row'>
                    <Button onClick={closeModal} borderRadius={12} key="cancel">
                        Отмена
                    </Button>
                    <Button
                        key="ok"
                        type="primary"
                        onClick={!isCertSelected ? setCertificateHandle : addCertificate}
                        borderRadius={12}
                        loading={isLoadingDetails}
                        disabled={!selectedCertificate}
                    >
                        <EditSquare /> {isCertSelected ? 'Подписать' : 'Выбрать сертификат'}
                    </Button>
                </Box>
            ]}
        >
            <Box margin={[0, 40]}>
                 <UserCertificateList
                    showTransactionDetails={showTransactionDetails}
                    selectedCertificate={selectedCertificate}
                    setSelectedCertificate={setSelectedCertificate}
                />
            </Box>
        </MiniModal>
    )
}
