import { Box, LoadingOutlined, Tabs, UserCertificateItem } from '@dltru/dfa-ui'
import { useCallback, useState } from 'react'
import style from './style.m.less'
import { useSelector } from 'react-redux'
import { transactionDetailsSelectors } from '@store/sign/selectors'

enum TabName {
    json = 'JSON',
    rpl = 'RPL',
}
const defaultTab = TabName.json

const LoadingDetails = () => (
  <Box align='center' justify='center' height={'100%'}>
    <LoadingOutlined style={{ fontSize: 36}}/>
  </Box>)

const TransactionDetails = ({ selectedCertificate } : any) => {
    const details = useSelector(transactionDetailsSelectors.selectItems)
    const isLoadingDetails = useSelector(transactionDetailsSelectors.selectIsLoading)
    const [activeTab, setActiveTab] = useState<string>(defaultTab)

    const detaislData = 
      details?.item
        ? details?.item.representation
        : details?.transaction
          ? details?.transaction.representation
          : details?.representation
    // server response options
    // details.transaction.representation.json
    // details.item.representation.json
    // details.representation.json
    // const key = details?.transaction ? 'transaction' : 'item'

    const switchRepresentation = useCallback((tab: string) => {
      setActiveTab(tab)
    }, [])

  return (
    <Box>
      <Box>
        Вы действительно хотите подписать документ электронной подписью?
      </Box>
          <UserCertificateItem
              key={selectedCertificate.uuid}
              certificate={selectedCertificate}
              isActive={false}
            />
         <Tabs onChange={switchRepresentation} activeKey={activeTab} style={{ border: 'none'}}>
            <Tabs.TabPane tab={TabName.json} key={TabName.json}>
              <Box className={style.transactionBox} padding={10}>
                {isLoadingDetails
                    ? <LoadingDetails />
                    : details
                  ? <div><pre>{ JSON.stringify(detaislData.json, null, 2) }</pre></div>
                  : 'Для просмотра деталей транзакции необходимо выбрать сертификат'}
              </Box>
            </Tabs.TabPane>
              <Tabs.TabPane tab={TabName.rpl} key={TabName.rpl}>
                <Box className={style.transactionBox} padding={10}>
                  {isLoadingDetails
                    ? <LoadingDetails />
                    : details
                    ? detaislData.rpl
                    : 'Для просмотра деталей транзакции необходимо выбрать сертификат'}
                </Box>
              </Tabs.TabPane>            
          </Tabs>
    </Box>
  )
}

export default TransactionDetails