import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import dayjs from 'dayjs'

import {
    CollectType,
    DfaStatusEnum,
    IssueTypeDfa,
    ServiceCodeFee,
    SettlementsType,
    getPennies,
} from '@dltru/dfa-models'
import {
    Alert,
    BankCollectCommission,
    Box,
    Button,
    Divider,
    EMPTY_FIELD_VALUE,
    MiniModal,
    MoneyAccountBlock,
    Price,
    QuestionCircle,
    Space,
} from '@dltru/dfa-ui'

import { authSelector } from '@store/auth/selectors'
import { setDfasDecisionIssueFeeAmount } from '@store/dfa/details'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import { getCalculateFeeHelper } from '@store/helper'
import { moneySelector } from '@store/money/selectors'

import { commonTextAccount, textForAccount, textForBank } from './consts'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    handleOk: () => void
}

export const SendForApproveModal: FC<IComponentProps> = ({
    isModalVisible,
    setIsModalVisible,
    handleOk,
}) => {
    const moneyAccount = useSelector(moneySelector.selectAccount)
    const [feeDecision, setFeeDecision] = useState<number | undefined>()
    const [feePaymentOrder, setFeePaymentOrder] = useState<number | undefined>()
    const [feeIssue, setFeeIssue] = useState<number | undefined>()
    const dispatch = useDispatch()

    const dfa = useSelector(dfaDetailsSelector.selectDfaDetails)
    const isManualIssue = dfa?.issue_type === IssueTypeDfa.manual
    const isBankCollectType = dfa?.payment_collect_type === CollectType.bank_account
    const isDirect = dfa?.issue_settlements_type === SettlementsType.direct
    const isModification = dfa?.status === DfaStatusEnum.modification
    const user_id = useSelector(authSelector.selectUserUid)

    const getCalculateFeeDecision = async () => {
        const operation_amount = getPennies(dfa.price_per_dfa) * Number(dfa.max_supply_invested)

        const fee = await getCalculateFeeHelper({
            operation_amount,
            service_code: ServiceCodeFee.createDfa,
            user_id,
            asset_id: dfa.id,
            start_date: dayjs(dfa.issue_start_date).toISOString(),
            end_date: dayjs(dfa.redeem_date).toISOString(),
        })
        setFeeDecision(
            typeof fee?.item?.FeeAmount === 'number' ? fee.item.FeeAmount / 100 : undefined,
        )
    }

    const getCalculateFeeIssue = async () => {
        const operation_amount = getPennies(dfa.price_per_dfa) * Number(dfa.max_supply_invested)

        const serviceCode = ServiceCodeFee.issueLong

        const fee = await getCalculateFeeHelper({
            service_code: serviceCode,
            operation_amount,
            user_id,
            asset_id: dfa.id,
            start_date: dayjs(dfa.issue_start_date).toISOString(),
            end_date: dayjs(dfa.redeem_date).toISOString(),
        })

        setFeeIssue(typeof fee?.item?.FeeAmount === 'number' ? fee.item.FeeAmount / 100 : undefined)

        dispatch(setDfasDecisionIssueFeeAmount(fee.item.FeeAmount))

        if (isBankCollectType) {
            const paymentOrderFee = await getCalculateFeeHelper({
                service_code: ServiceCodeFee.paymentOrder,
                operation_amount,
                user_id,
                asset_id: dfa.id,
                start_date: dayjs(dfa.issue_start_date).toISOString(),
                end_date: dayjs(dfa.redeem_date).toISOString(),
            })
            setFeePaymentOrder(
                typeof paymentOrderFee?.item?.fee_amount === 'number'
                    ? paymentOrderFee.item.fee_amount / 100
                    : undefined,
            )
        } else {
            setFeePaymentOrder(undefined)
        }
    }

    useEffect(() => {
        if (isModification) {
            setFeeDecision(undefined)
        } else {
            getCalculateFeeDecision()
        }
    }, [isModification])

    useEffect(() => {
        getCalculateFeeIssue()
    }, [
        dfa.price_per_dfa,
        dfa.max_supply_invested,
        dfa.issue_end_date,
        dfa.redeem_date,
        isBankCollectType,
        isManualIssue,
        isDirect,
    ])

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    // вернул старый вариант без if
    const isDisableBtn =
        (moneyAccount?.balance?.available ?? 0) <
        (feeDecision ?? 0) * 100 +
            (isBankCollectType || isDirect ? ((feePaymentOrder || 0) + (feeIssue || 0)) * 100 : 0)

    return (
        <MiniModal
            width={600}
            title={
                <Space size={0}>
                    <QuestionCircle className="modal-icon modal-question-icon" />
                    Отправить заявление на рассмотрение
                </Space>
            }
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={[
                <Button key="cancel" borderRadius={12} onClick={handleCancel}>
                    Отмена
                </Button>,
                <Button
                    key="ok"
                    borderRadius={12}
                    type="primary"
                    onClick={handleOk}
                    disabled={isDisableBtn}
                >
                    Да, направить заявление
                </Button>,
            ]}
        >
            <Box margin={[0, 40]}>
                Вы действительно хотите направить заявление о допуске ЦФА к выпуску на рассмотрение
                Оператору ИС?
                <Divider margin={[16, 0]} />
                {!isModification && (
                    <Box margin={[0, 0, 16, 16]}>
                        Комиссия за рассмотрение заявления о допуске ЦФА к выпуску <br />
                        {feeDecision !== undefined ? (
                            <Price price={feeDecision} blackCurrency />
                        ) : (
                            EMPTY_FIELD_VALUE
                        )}
                    </Box>
                )}
                <Box margin={[0, 0, 16, 16]}>
                    Комиссия за организацию выпуска ЦФА в ИС&nbsp;{isBankCollectType ? '*' : ''}
                    <br />
                    {feeIssue !== undefined ? (
                        <Price price={feeIssue + (feePaymentOrder ?? 0)} blackCurrency />
                    ) : (
                        EMPTY_FIELD_VALUE
                    )}
                </Box>
                {isBankCollectType && (
                    <BankCollectCommission
                        feePaymentOrder={feePaymentOrder ? feePaymentOrder * 100 : feePaymentOrder}
                    />
                )}
                <MoneyAccountBlock
                    accountNumber={moneyAccount?.number}
                    balance={moneyAccount?.balance.available}
                />
                <Box margin={[16, 0, 0, 0]}>
                    <Alert
                        type="info"
                        showIcon
                        description={`${isModification ? '' : commonTextAccount} ${
                            isDirect || isBankCollectType ? textForBank : textForAccount
                        }`}
                    />
                </Box>
            </Box>
        </MiniModal>
    )
}
