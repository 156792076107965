import React, { FC, useState } from 'react'
import { InstanceProps, create } from 'react-modal-promise'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import {
    ISecondaryDeal,
    ISecondaryOrderShort,
    SettlementsType,
    UnionDealFront,
    paymentMethodTranslate,
    unionTypeBackToFront,
} from '@dltru/dfa-models'
import { BodyTitle, Button, DateTime, Divider, Price, LabelRow as Row } from '@dltru/dfa-ui'

import { authSelector } from '@store/auth/selectors'

import { SecondaryOrderDetailsModal } from '../../Orders/View'

import { BaseModal } from './Base'
import { CommonInfoBlock } from './CommonInfoBlock'

interface IDealViewModal extends InstanceProps<boolean> {
    deal: ISecondaryDeal
    type: UnionDealFront['type']
}

const SecondaryModal: FC<IDealViewModal> = ({ deal, type, onResolve }) => {
    const [isCardModalVisible, setIsCardModalVisible] = useState(false)
    const [currentOrder, setCurrentOrder] = useState<ISecondaryOrderShort>()
    const userUid = useSelector(authSelector.selectUserUid)
    const isBuyer = deal?.buy_order && deal.buy_order.user_id === userUid
    const isSeller = deal?.sale_order && deal.sale_order.user_id === userUid

    const openOrderModal = (order: ISecondaryOrderShort) => {
        setCurrentOrder(unionTypeBackToFront(order))
        setIsCardModalVisible(true)
    }
    return (
        <>
            <BaseModal deal={deal} onResolve={onResolve}>
                <BodyTitle
                    title={`Детали ${type === 'buy' ? 'покупки' : 'продажи'}`}
                    id={deal.id}
                />
                <Row label="Дата и время сделки" className="label-row__space">
                    <DateTime value={deal.executed_at} />
                </Row>
                <Row label="Покупатель" className="label-row__space">
                    <Link to={`/clients/${deal.buy_order.user_id}`}>
                        {deal.buy_order.user_full_name}
                    </Link>
                </Row>
                <Row label="Продавец" className="label-row__space">
                    <Link to={`/clients/${deal.sale_order.user_id}`}>
                        {deal.sale_order.user_full_name}
                    </Link>
                </Row>
                <Divider />
                <Row
                    label={`Заявка на ${type === 'buy' ? 'покупку' : 'продажу'}`}
                    className="label-row__space"
                >
                    <Button
                        type="link"
                        className="link-button"
                        onClick={() =>
                            openOrderModal(type === 'buy' ? deal.buy_order : deal.sale_order)
                        }
                    >
                        ID {type === 'buy' ? deal.buy_order.id : deal.sale_order.id}
                    </Button>
                </Row>
                <Divider />
                <CommonInfoBlock deal={deal} />
                <Row label="Способ расчётов" className="label-row__space">
                    {paymentMethodTranslate(
                        deal.sale_order.payment_collect_type,
                        SettlementsType.platform,
                    )}
                </Row>
                <Divider />
                {isBuyer && (
                    <Row label="Комиссия за покупку" className="label-row__space">
                        <Price light price={(deal?.buy_order?.buy_fee_amount || 0) / 100} />
                    </Row>
                )}
                {isSeller && (
                    <Row label="Комиссия за продажу" className="label-row__space">
                        <Price light price={(deal?.sale_order?.sell_fee_amount || 0) / 100} />
                    </Row>
                )}
            </BaseModal>
            <SecondaryOrderDetailsModal
                isModalVisible={isCardModalVisible}
                setIsModalVisible={setIsCardModalVisible}
                order={currentOrder}
                secondModal
                onlyDetails
            />
        </>
    )
}

export const secondaryModal = create(SecondaryModal)
