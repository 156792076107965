// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".transactionBox_PpMuo {\n  border: 1px #B9BAC1 solid;\n  border-radius: 8px;\n  font-size: 14px;\n  list-style: 24px;\n  color: #B9BAC1;\n  background-color: #F8F8FA;\n  min-height: 300px;\n  max-width: 600px;\n  overflow-y: none;\n  word-break: break-all;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Modals/Certificate/style.m.less"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;EACA,yBAAA;EACA,iBAAA;EACA,gBAAA;EACA,gBAAA;EACA,qBAAA;AACF","sourcesContent":[".transactionBox{\n  border: 1px #B9BAC1 solid;\n  border-radius: 8px;\n  font-size: 14px;\n  list-style: 24px;\n  color: #B9BAC1;\n  background-color: #F8F8FA;\n  min-height: 300px;\n  max-width: 600px;\n  overflow-y: none;\n  word-break: break-all;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transactionBox": "transactionBox_PpMuo"
};
export default ___CSS_LOADER_EXPORT___;
