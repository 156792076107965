import React, { useEffect, useState } from 'react'
import { InstanceProps, create } from 'react-modal-promise'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import {
    IEmissionDeal,
    IEmissionOrderDeal,
    paymentMethodTranslate,
    unionTypeBackToFront,
} from '@dltru/dfa-models'
import { BodyTitle, Button, DateTime, Divider, Price, LabelRow as Row } from '@dltru/dfa-ui'

import { authSelector } from '@store/auth/selectors'
import { getDfaById } from '@store/dfa/details'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { EmissionOrderDetailsModal } from '@components/Modals/Orders'

import { BaseModal } from './Base'
import { CommonInfoBlock } from './CommonInfoBlock'

interface IDealViewModal extends InstanceProps<boolean> {
    deal: IEmissionDeal
}

const EmissionModal: React.FC<IDealViewModal> = ({ deal, onResolve }) => {
    const [isCardModalVisible, setIsCardModalVisible] = useState(false)
    const [currentOrder, setCurrentOrder] = useState<IEmissionOrderDeal>()
    const userUuid = useSelector(authSelector.selectUserUid)
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const isInvestor = deal.order.user_id === userUuid
    const reduxDispatch = useDispatch()

    useEffect(() => {
        if (dfaDetails.id !== deal.asset_id) {
            reduxDispatch(getDfaById({ dfaId: deal.asset_id }))
        }
    }, [])
    const openOrderModal = (order: IEmissionOrderDeal) => {
        setCurrentOrder(unionTypeBackToFront(order))
        setIsCardModalVisible(true)
    }

    return (
        <>
            <BaseModal deal={deal} onResolve={onResolve}>
                <BodyTitle title="Детали сделки размещения" id={deal.id} />
                <Row label="Дата и время сделки" className="label-row__space">
                    <DateTime value={deal.executed_at} />
                </Row>
                <Row label="Эмитент" className="label-row__space">
                    <Link to={`/clients/${deal.emitter_id}`}>{deal.emitter_full_name}</Link>
                </Row>
                <Row label="Первый Приобретатель" className="label-row__space">
                    <Link to={`/clients/${deal.order.user_id}`}>{deal.order.user_full_name}</Link>
                </Row>
                <Divider />
                <Row label="Заявка на покупку" className="label-row__space">
                    <Button
                        className="link-button"
                        type="link"
                        onClick={() => openOrderModal(deal.order)}
                    >
                        ID {deal.order.id}
                    </Button>
                </Row>
                <Divider />
                <CommonInfoBlock deal={deal} />
                <Row label="Способ расчётов" className="label-row__space">
                    {paymentMethodTranslate(
                        dfaDetails.payment_collect_type,
                        dfaDetails.issue_settlements_type,
                    )}
                </Row>
                <Divider />
                {isInvestor && (
                    <Row label="Комиссия за приобретение ЦФА" className="label-row__space">
                        <Price light price={deal.order.acquisition_fee_amount / 100} />
                    </Row>
                )}
            </BaseModal>
            <EmissionOrderDetailsModal
                isModalVisible={isCardModalVisible}
                setIsModalVisible={setIsCardModalVisible}
                order={currentOrder}
                secondModal
            />
        </>
    )
}

export const emissionModal = create(EmissionModal)
