import React, { FC, useEffect } from 'react'
import { InstanceProps, create } from 'react-modal-promise'
import { useDispatch, useSelector } from 'react-redux'

import { IRepaymentDeal, paymentMethodTranslate } from '@dltru/dfa-models'
import { BodyTitle, DateTime, Divider, Price, LabelRow as Row } from '@dltru/dfa-ui'

import { authSelector } from '@store/auth/selectors'
import { getDfaById } from '@store/dfa/details'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { BaseModal } from './Base'
import { CommonInfoBlock } from './CommonInfoBlock'

interface IDealViewModal extends InstanceProps<boolean> {
    deal: IRepaymentDeal
}

const RepaymentModal: FC<IDealViewModal> = ({ deal, onResolve }) => {
    const userUuid = useSelector(authSelector.selectUserUid)
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const isEmitter = deal.emitter_id === userUuid
    const reduxDispatch = useDispatch()

    useEffect(() => {
        if (dfaDetails.id !== deal.asset_id) {
            reduxDispatch(getDfaById({ dfaId: deal.asset_id }))
        }
    }, [])

    return (
        <BaseModal deal={deal} onResolve={onResolve}>
            <BodyTitle title="Детали погашения" id={deal.id} />
            <Row label="Дата и время погашения" className="label-row__space">
                <DateTime value={deal.executed_at} />
            </Row>
            <Divider />
            <CommonInfoBlock deal={deal} isRepayment />
            <Row label="Способ расчётов" className="label-row__space">
                {paymentMethodTranslate(
                    dfaDetails?.current_repayment_collect_type ??
                        dfaDetails?.repayment_collect_type,
                    dfaDetails?.current_repayment_settlements_type ??
                        dfaDetails?.repayment_settlements_type,
                )}
            </Row>
            {isEmitter && (
                <>
                    <Divider />
                    <Row label="Комиссия за погашение ЦФА" className="label-row__space">
                        <Price
                            light
                            price={
                                (deal.amount_dfa / (dfaDetails?.original_amount ?? 0)) *
                                    (dfaDetails.decision?.repayment_fee_amount / 100) || 0
                            }
                        />
                    </Row>
                </>
            )}
        </BaseModal>
    )
}

export const repaymentModal = create(RepaymentModal)
